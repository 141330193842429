var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"home"},[_vm._m(0),_c('div',{staticClass:"yago-image-header subpage row",attrs:{"id":"intro"}},[_c('img',{attrs:{"src":require("@/assets/images/yago_deck_header_image.jpg"),"alt":""}}),_c('div',{staticClass:"overlay col-12 px-5 px-sm-6 px-lg-7"},[_c('div',{staticClass:"content-container col col-sm-8 col-xl-6"},[_c('div',{staticClass:"content"},[_c('h1',[_vm._v(_vm._s(_vm.$t('homeview.headline')))]),_c('p',[_vm._v(_vm._s(_vm.$t('homeview.tagline')))])])])])]),_c('div',{staticClass:"yago-split-content"},[_c('div',{staticClass:"col left"},[(_vm.$store.getters.getXRSupported)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"text"},[_c('h2',[_vm._v(_vm._s(_vm.$t('homeview.trial.webxrSupported.title')))]),_c('p',[_vm._v(_vm._s(_vm.$t('homeview.trial.webxrSupported.text.1')))]),_c('p',[_vm._v(_vm._s(_vm.$t('homeview.trial.webxrSupported.text.2')))]),_c('p',{class:{ 'orientation-text': true, disabled: !_vm.inLandscape }},[_vm._v(" "+_vm._s(_vm.$t('homeview.trial.webxrSupported.orientationtext'))+" ")]),_c('button',{staticClass:"start-webxr-button",attrs:{"disabled":_vm.inLandscape},on:{"click":function($event){return _vm.launchXR()}}},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/icons/artboard_white.svg"),"alt":""}}),_vm._v(" "+_vm._s(_vm.$t('start'))+" ")])])]):_c('div',{staticClass:"container"},[_c('div',{staticClass:"text"},[_c('h2',[_vm._v(_vm._s(_vm.$t('homeview.trial.webxrNotSupported.title')))]),_c('p',[_vm._v(_vm._s(_vm.$t('homeview.trial.webxrNotSupported.text.1')))]),_c('p',[_vm._v(_vm._s(_vm.$t('homeview.trial.webxrNotSupported.text.2')))]),_c('p',[_vm._v(_vm._s(_vm.$t('homeview.trial.webxrNotSupported.text.3')))]),_c('p',{domProps:{"innerHTML":_vm._s(
                            _vm.$t('homeview.trial.webxrNotSupported.text.4', {
                                href: 'https://developers.google.com/ar/devices',
                            })
                        )}}),_c('p',[_vm._v(_vm._s(_vm.$t('homeview.trial.webxrNotSupported.text.6')))])])])]),(!_vm.$store.getters.getXRSupported)?_c('div',{staticClass:"col right"},[_vm._m(1)]):_vm._e()]),(_vm.$store.getters.getXRSupported)?_c('div',{staticClass:"yago-features"},[_c('div',{staticClass:"title"},[_c('h2',[_vm._v(_vm._s(_vm.$t('homeview.features.title')))])]),_c('div',{staticClass:"features-container"},[_c('div',{staticClass:"features-content"},[_c('div',{staticClass:"features"},[_c('div',{staticClass:"feature"},[_c('div',{staticClass:"content"},[_vm._m(2),_c('h3',[_vm._v(_vm._s(_vm.$t('homeview.features.feature.1.title')))]),_c('span',[_vm._v(_vm._s(_vm.$t('homeview.features.feature.1.text')))])])]),_c('div',{staticClass:"feature"},[_c('div',{staticClass:"content"},[_vm._m(3),_c('h3',[_vm._v(_vm._s(_vm.$t('homeview.features.feature.2.title')))]),_c('span',[_vm._v(_vm._s(_vm.$t('homeview.features.feature.2.text')))])])]),_c('div',{staticClass:"feature"},[_c('div',{staticClass:"content"},[_vm._m(4),_c('h3',[_vm._v(_vm._s(_vm.$t('homeview.features.feature.3.title')))]),_c('span',[_vm._v(_vm._s(_vm.$t('homeview.features.feature.3.text')))])])])])])])]):_vm._e(),_c('div',{staticClass:"yago-contact row",attrs:{"id":"contact"}},[_c('div',{staticClass:"title"},[_c('h2',[_vm._v(_vm._s(_vm.$t('homeview.contact.title')))])]),_vm._m(5),_c('div',{staticClass:"text-container"},[_c('p',[_vm._v(_vm._s(_vm.$t('homeview.contact.text')))])])]),_c('footer',[_vm._m(6),_c('a',{attrs:{"href":"https://yago.cloud/security-policy"}},[_vm._v(_vm._s(_vm.$t('homeview.footer.securityPolicy')))]),_c('a',{attrs:{"href":"https://yago.cloud/de/agb/"}},[_vm._v(_vm._s(_vm.$t('homeview.footer.tos')))])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('nav',{staticClass:"navbar"},[_c('a',{staticClass:"navbar-brand",attrs:{"href":"https://yago.cloud/"}},[_c('img',{attrs:{"src":require("@/assets/images/logos/yago.svg"),"alt":"yago"}})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require("@/assets/images/qr_code.svg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"icon-container"},[_c('img',{attrs:{"src":require("@/assets/images/icons/camera_black.svg"),"alt":""}}),_c('div',{staticClass:"stepCount"},[_vm._v("1")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"icon-container"},[_c('img',{attrs:{"src":require("@/assets/images/icons/technology_ar_searching_ar_map_smartphone_black.svg"),"alt":""}}),_c('div',{staticClass:"stepCount"},[_vm._v("2")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"icon-container"},[_c('img',{attrs:{"src":require("@/assets/images/icons/table_lamp_black.svg"),"alt":""}}),_c('div',{staticClass:"stepCount"},[_vm._v("3")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"email-button-container"},[_c('a',{attrs:{"href":"mailto:hello@yago.cloud"}},[_vm._v("hello@yago.cloud")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_vm._v("© 2023 by "),_c('a',{attrs:{"href":"https://bitforge.ch","target":"_blank"}},[_vm._v("Bitforge AG")])])
}]

export { render, staticRenderFns }