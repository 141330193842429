import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class App extends Vue {
    private xrSupported = false;

    public async mounted(): Promise<void> {
        // Check if WebXR is Supported
        this.xrSupported = navigator.xr !== undefined;


        if (this.xrSupported && navigator.xr) {
            // Check for immersive mode for Chome on Desktop
            this.xrSupported = await navigator.xr.isSessionSupported('immersive-ar');
        }

        if (navigator.userAgent.includes('WebXRViewer')) {
            // Show not supported message when browser is 'Mozilla WebXR Viewer' so the user can't start webXR.
            this.xrSupported = false;
        }

        this.$store.commit('setXRSupported', this.xrSupported);
        this.setLanguge();

        if (this.xrSupported) {
            this.retrieveModels();
        }
    }

    private async retrieveModels(): Promise<void> {
        if (this.$store.getters.getXRSupported == true) {
            try {
                this.$store.dispatch('retrieveModels');
            } catch (err: any) {
                console.log(err);
            }
        }
    }

    private setLanguge(): void {
        const userLang = navigator.language;

        if (userLang.includes('en')) {
            this.$i18n.locale = 'en';
        } else if (userLang.includes('de')) {
            this.$i18n.locale = 'de';
        }
    }
}
