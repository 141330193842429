import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Home extends Vue {
    private inLandscape = false;

    private mounted(): void {
        window.addEventListener('orientationchange', this.onOrientationChanged);
        this.onOrientationChanged(); // Initial orientation check on page load
    }

    private launchXR(): void {
        if (!this.inLandscape) {
            this.$router.push('/webxr');
        }
    }

    private onOrientationChanged(): void {
        this.inLandscape = screen.orientation.type.startsWith('landscape');
    }

    private beforeDestroy(): void {
        window.removeEventListener('orientationchange', this.onOrientationChanged);
    }
}
