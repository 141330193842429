import * as THREE from 'three';
import { Configuration, type Model, ModelsApi, ModelStatus } from '@bitforgehq/yago-api-client';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { ACESFilmicToneMapping, Object3D, sRGBEncoding, Vector3 } from 'three';
import router from '@/router';

const state: WebXR = {
    session: null,
    sessionEstablishedTracking: false,
    renderer: null,
    canvas: null,
    webGLRenderingContext: null,
    scene: null,
    cursor: null,
    previewMaterial: null,
    previewModel: null,
    gltfLoader: new GLTFLoader(),
    models: [],
    loadedModels3D: {},
    placedModels: [],
    selectedModel: null, // Selected Model in Deck Component
    focusedModel: null, // Focused Model with Raycast
};

const getters = {
    getSession(state: WebXR): XRSession | null {
        return state.session;
    },

    getSessionEstablishedTracking(state: WebXR): boolean {
        return state.sessionEstablishedTracking;
    },

    getRenderer(state: WebXR): THREE.Renderer | null {
        return state.renderer;
    },

    getWebGLRenderingContext(state: WebXR): WebGLRenderingContext | null {
        return state.webGLRenderingContext;
    },

    getCanvas(state: WebXR): HTMLCanvasElement | null {
        return state.canvas;
    },

    getScene(state: WebXR): THREE.Scene {
        if (!state.scene) {
            state.scene = new THREE.Scene();
        }
        return state.scene;
    },

    getCursor(state: WebXR): THREE.Group | null {
        return state.cursor;
    },

    getCursorPosition(state: WebXR): Vector3 | undefined {
        return state.cursor?.position;
    },

    getPreviewMaterial(state: WebXR): THREE.MeshStandardMaterial | null {
        return state.previewMaterial;
    },

    getPreviewModel(state: WebXR): THREE.Object3D | null {
        return state.previewModel;
    },

    getGLTFLoader(state: WebXR): GLTFLoader {
        return state.gltfLoader;
    },

    getModels(state: WebXR): Model[] {
        return state.models;
    },

    getLoadedModels3D(state: WebXR): { [id: string]: THREE.Group } {
        return state.loadedModels3D;
    },

    getPlacedModels(state: WebXR): Model[] {
        return state.placedModels;
    },

    getSumOfPlacedModels(state: WebXR): string {
        let sum = 0;
        state.placedModels.forEach(model => {
            if (model.price) {
                sum += parseFloat(model.price);
            }
        });
        return sum.toFixed(2);
    },

    getSelectedModel(state: WebXR): Model | null {
        return state.selectedModel;
    },

    getFocusedModel(state: WebXR): THREE.Object3D | null {
        return state.focusedModel;
    },
};

const mutations = {
    setSession(state: WebXR, session: XRSession | null): void {
        state.session = session;
    },

    endSession(state: WebXR): void {
        state.session?.end();
    },

    setSessionEstablishedTracking(state: WebXR, tracking: boolean): void {
        state.sessionEstablishedTracking = tracking;
    },

    setCanvas(state: WebXR): void {
        state.canvas = document.querySelector('.glcanvas') as HTMLCanvasElement;
    },

    getWebGLRenderingContext(state: WebXR): void {
        state.webGLRenderingContext = state.canvas?.getContext('webgl', {
            xrCompatible: true,
        }) as WebGLRenderingContext;
    },

    disposeRenderer(state: WebXR): void {
        state.renderer?.dispose();
    },

    setScene(state: WebXR, scene: THREE.Scene | null): void {
        state.scene = scene;
    },

    addToScene(state: WebXR, object: Object3D) {
        if (state.scene) {
            state.scene.add(object);
        } else {
            state.scene = new THREE.Scene();
            state.scene!.add(object);
        }
    },

    removeFromScene(state: WebXR, object: THREE.Object3D): void {
        state.scene?.remove(object);
    },

    setCursor(state: WebXR, cursor: THREE.Group): void {
        state.cursor = cursor;
    },

    createPreviewMaterial(state: WebXR): void {
        // Semi transparent (ghost) material for models not placed yet
        const transMat = new THREE.MeshStandardMaterial({
            color: 0xffffff,
            roughness: 0.5,
            metalness: 0.5,
        });
        transMat.transparent = true;
        transMat.opacity = 0.4;
        transMat.side = THREE.DoubleSide;

        state.previewMaterial = transMat;
    },

    updatePreviewModel(state: WebXR): void {
        if (!state.selectedModel) return;
        const isSelectedModel = state.previewModel?.name == `mdl-${state.selectedModel.slug}`;

        // Remove prewviewModel when it's not the selected model
        if (state.previewModel && !isSelectedModel) {
            state.cursor?.remove(state.previewModel);
            state.previewModel = null;
        }

        // Add preview model when it require and model is loaded
        const addPreviewModel = !isSelectedModel && state.loadedModels3D[state.selectedModel.id];
        if (addPreviewModel) {
            state.previewModel = state.loadedModels3D[state.selectedModel.id].clone();
            state.previewModel.traverse(obj => obj.layers.set(0));
            state.previewModel.traverse((object: THREE.Object3D<THREE.Event>) => {
                const mesh = object as THREE.Mesh;
                const material = mesh.material as THREE.MeshStandardMaterial;
                if (material && state.previewMaterial) {
                    mesh.material = state.previewMaterial;
                }
            });

            state.cursor?.add(state.previewModel);
        }
    },

    resetPreviewStuff(state: WebXR): void {
        state.previewMaterial = null;
        state.previewModel = null;
    },

    setCursorPosition(state: WebXR, pose: Vector3) {
        state.cursor?.position.set(pose.x, pose.y, pose.z);
    },

    setModels(state: WebXR, models: Model[]): void {
        state.models = models;
    },

    addLoadedModels3D(state: WebXR, object3D: THREE.Group): void {
        state.loadedModels3D[object3D.userData.modelData.id] = object3D;
    },

    addPlacedModel(state: WebXR, model3D: Model): void {
        state.placedModels.push(model3D);
    },

    removePlacedModel(state: WebXR, model: Model): void {
        const indexToRemove = state.placedModels.findIndex(mld => mld.name == model.name);
        if (indexToRemove >= 0) {
            state.placedModels.splice(indexToRemove, 1);
        }
    },

    removeAllPlacedModels(state: WebXR): void {
        state.placedModels = [];
    },

    setSelectedModel(state: WebXR, model: Model | null): void {
        state.selectedModel = model;
    },

    setFocusedModel(state: WebXR, focusedModel: THREE.Object3D | null): void {
        state.focusedModel = focusedModel;
    },
};

const actions = {
    async createSession(context: any): Promise<void> {
        const xr = (navigator as any).xr;
        const domOverlay = document.querySelector('.domOverlay');
        const session = await xr.requestSession('immersive-ar', {
            requiredFeatures: ['hit-test', 'light-estimation'],
            optionalFeatures: ['dom-overlay'],
            domOverlay: { root: domOverlay },
        });

        if (session != null) {
            context.commit('setSession', session);
            context.commit('setXRActive', true);

            session.addEventListener('end', () => {
                context.commit('setSession', null);
                context.commit('setScene', null);
                context.commit('disposeRenderer');
                context.commit('removeAllPlacedModels');
                context.commit('setXRActive', false);
                context.commit('setUIActive', false);
                context.commit('setSessionEstablishedTracking', false);
                context.commit('resetPreviewStuff');

                if (router.currentRoute.name == 'webxr')
                    router.push('/');
            });
        }
    },

    async createRenderer(context: any): Promise<void> {
        // Create WebGL rendering context
        context.commit('setCanvas');
        context.commit('getWebGLRenderingContext');

        state.renderer = new THREE.WebGLRenderer({
            alpha: true,
            antialias: true,
            canvas: context.getters.getCanvas,
            preserveDrawingBuffer: true,
            context: context.getters.getWebGLRenderingContext,
        });
        state.renderer.autoClear = true;
        state.renderer.outputEncoding = sRGBEncoding;
        state.renderer.physicallyCorrectLights = true;
        state.renderer.toneMapping = ACESFilmicToneMapping;
    },

    async loadCursor(context: any): Promise<void> {
        // Nopsy is the name of our aim cursor
        // The name was invented by Parki Banya Gang
        // The original model was built with TinkerCAD:
        // https://bit.ly/3C668ge
        const gltfLoader = context.getters.getGLTFLoader as GLTFLoader;
        const gltf = await gltfLoader.loadAsync(
            'nopsy.glb'
        );

        const cursor = gltf.scene;
        cursor.visible = false;
        cursor.castShadow = false;
        cursor.traverse(obj => obj.layers.set(0));
        cursor.name = 'nopsy';

        context.commit('setCursor', cursor);
        context.commit('addToScene', cursor);
    },

    async loadModel(context: any, model: Model): Promise<void> {
        if (!model || !model.glb) throw `Could not find model infos for ${model}`;

        if (!context.getters.getLoadedModels3D[model.id]) {
            const gltfLoader = context.getters.getGLTFLoader as GLTFLoader;
            const gltf = await gltfLoader.loadAsync(model.glb);
            const object3D = gltf.scene;
            object3D.name = `mdl-${model.slug}`;
            object3D.traverse(obj => (obj.userData = { modelData: model, boundingBox: null }));
            context.commit('addLoadedModels3D', object3D);
        }
    },

    async placeModel(context: any, model: Model): Promise<void> {
        if (!model || !model.glb) throw `Could not find model infos for ${model}`;

        if (!context.getters.getLoadedModels3D[model.id]) {
            await context.dispatch('loadModel', model);
        }

        // Create copy of 3D model and API model data
        const placedModel: Object3D = context.getters.getLoadedModels3D[model.id].clone();
        // Set position to current cursor position
        placedModel.position.copy(context.getters.getCursorPosition);
        // Set layer for model and all children
        placedModel.traverse(obj => obj.layers.set(1));
        // Create model bounding box and append it to userData for raycast intersection check
        const box3 = new THREE.Box3();
        box3.setFromObject(placedModel);
        placedModel.traverse(obj => obj.userData.boundingBox = box3);

        // Add clone to scene
        context.commit('addToScene', placedModel);

        // Append placed model to placedModels
        const modelClone = { ...(placedModel.userData.modelData as Model) };
        modelClone.number = placedModel.id;
        context.commit('addPlacedModel', modelClone);
    },

    removeFocusedModel(context: any): void {
        let focusedModel = context.getters.getFocusedModel;

        // Find model root object
        while (focusedModel.parent && !focusedModel.name.startsWith('mdl')) {
            focusedModel = focusedModel.parent;
        }

        const model = focusedModel.userData.modelData as Model;
        context.commit('removePlacedModel', model);
        context.commit('removeFromScene', focusedModel);
    },

    async retrieveModels(context: any): Promise<void> {
        const modelsApi = new ModelsApi(new Configuration({ apiKey: process.env.VUE_APP_API_KEY }));
        const models = await modelsApi.modelsList({ project: process.env.VUE_APP_DEFAULT_PROJEKT_ID });
        const activeModels = models.filter((model: Model) => model.status != ModelStatus.Draft);
        context.commit('setModels', activeModels);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};

interface WebXR {
    session: XRSession | null;
    sessionEstablishedTracking: boolean,
    canvas: HTMLCanvasElement | null;
    renderer: THREE.WebGLRenderer | null;
    webGLRenderingContext: WebGLRenderingContext | null;
    scene: THREE.Scene | null;
    cursor: THREE.Group | null;
    previewMaterial: THREE.MeshStandardMaterial | null;
    previewModel: THREE.Object3D | null;
    gltfLoader: GLTFLoader;
    models: Model[];
    loadedModels3D: { [id: string]: THREE.Group };
    placedModels: Model[];
    selectedModel: Model | null;
    focusedModel: THREE.Object3D | null;
}
